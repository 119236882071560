import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/common/utils/barlow-font.ts\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"BarlowFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/app/src/app/_components/layout/sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRConfigProvider"] */ "/app/src/app/_components/layout/SwrConfigProviderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatButton"] */ "/app/src/features/chat/_components/ChatButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagProvider"] */ "/app/src/features/feature-flags/context-provider/_components/FeatureFlagContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/features/tooltip/_components/TooltipContext.tsx");
